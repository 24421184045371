<template>
  <div class="optimize">
    <div class="optimize_content">
      <div class="optimize_label flex">
        <p class="optimize_label-left">网站TDK</p>
      </div>
      <el-divider></el-divider>
      <el-form :model="ruleForm" ref="ruleForm" label-width="3rem">
        <el-form-item label="SEO标题" prop="seoTitle">
          <el-input v-model="ruleForm.seoTitle" />
          <p>请用英文的“&nbsp;&nbsp;-&nbsp;&nbsp;”隔开</p>
        </el-form-item>
        <el-form-item label="SEO关键词" prop="seoKey">
          <el-input v-model="ruleForm.seoKeyword" />
          <p>请用英文的逗号“&nbsp;&nbsp;,&nbsp;&nbsp;”隔开</p>
        </el-form-item>
        <el-form-item label="SEO描述" prop="seoDesc">
          <el-input type="textarea" v-model="ruleForm.seoDesc" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="saveLoad" @click="save">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import api from "@/api/website.js"
export default {
  data() {
    return {
      ruleForm: {
        seoTitle: "",
        seoKeyword: "",
        seoDesc: "",
      },
      saveLoad: false,
    }
  },
  methods: {
    // 保存seo数据
    async save() {
      this.saveLoad = true
      try {
        let condition = this.ruleForm
        const res = await api.saveWebInfo(condition)
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "保存成功！",
            duration: 1000,
          })
          this.saveLoad = false
        } else {
          this.$message.error("网络故障！")
          this.saveLoad = false
        }
      } catch (error) {
        this.$message.error(error)
        this.saveLoad = false
      }
    },
  },
  mounted() {
    this.ruleForm = this.$store.getters.seoContent
  },
}
</script>

<style lang="scss">
$fontColor: #333333;
$fontShallow: #cccccc;
$bgColor: #ffffff;

.optimize {
  padding: 10px;
  &_content {
    background-color: $bgColor;
    border-radius: 10px;
    padding: 20px 0;
    color: $fontColor;
    margin-bottom: 20px;
  }

  &_label {
    align-items: center;

    &-left {
      border-left: 6px solid #31b1ff;
      padding-left: 5px;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .el-form {
    text-align: left;
  }

  .el-input,
  .el-textarea {
    width: 400px;
  }
}
</style>
